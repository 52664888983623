import { type BaseListing, type FeedItem, ListingPriceType } from '@kijiji/generated/graphql-types'

import { type CoreListingCardData } from '@/components/shared/core-listing-card/CoreListingCard'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'

/**
 * Utility functions for converting listing data responses to be compatible with the CoreListingCardData type.
 * Needed after migrating HP galleries from LegacyCarousel to ListingsCarousel.
 * We should be able to fix by updating the Anvil schema/resolvers to return the correct data types.
 */
const PRICE_MAP: Record<string, ListingPriceType> = {
  FIXED: ListingPriceType.Fixed,
  CONTACT: ListingPriceType.Contact,
  DRIVE_AWAY: ListingPriceType.DriveAway,
  FREE: ListingPriceType.Free,
  GIVE_AWAY: ListingPriceType.GiveAway,
  MAKE_OFFER: ListingPriceType.MakeOffer,
  MONTHLY: ListingPriceType.Monthly,
  SPECIFIED_AMOUNT: ListingPriceType.SpecifiedAmount,
  STANDARD_MONTHLY: ListingPriceType.StandardMonthly,
  SWAP_TRADE: ListingPriceType.SwapTrade,
}

/**
 * Maps price type strings to one compatible with ListingPriceType
 * @param type - can sometimes be sentenced case, so we need to convert to uppercase
 * @returns corresponding ListingPriceType, or defaults to Contact
 */
const getPriceType = (type?: string | null) => {
  return PRICE_MAP[type?.toUpperCase() ?? ''] ?? ListingPriceType.Contact
}

type GenericPriceType = {
  type?: string | null
  __typename?: string
  amount?: number | null
  categoryId?: number
}

/**
 * Maps an unknown price type to CoreListingCardData['price'] type
 * @param price - The price object of type `GenericPriceType` to be mapped.
 * @returns An object compatible with CoreListingCardData['price'] type.
 */
export const mapPriceToListingPriceType = (
  price: GenericPriceType
): CoreListingCardData['price'] => {
  const type = getPriceType(price?.type)

  if (type === ListingPriceType.Contact) {
    return { type }
  }
  return { type, amount: isAmountPrice(price) ? price?.amount : undefined }
}

type MapToCoreListingCardDataInput = BaseListing & Pick<FeedItem, 'price' | 'seoUrl'>

/**
 * item.imageUrls && item.price both come back in formats that do not match the CoreListingCardData type; will need Anvil work to fix
 * locationName not provided in response; will need Anvil work to fix
 */

/**
 * The GetFeedRecommendations & GetRecentlyViewedListings queries return data in a format that is not compatible with the CoreListingCardData type.
 *
 * Until we fix on Anvil, we need to massage the data before passing it to the CoreListingCard component.
 * @param listing - The input listing data to be mapped.
 * @returns The mapped core listing card data.
 */
export const mapToCoreListingCardData = (
  listing: MapToCoreListingCardDataInput
): CoreListingCardData => {
  const { id, categoryId, imageUrls, price, seoUrl, title, locationId } = listing

  return {
    id: id,
    categoryId: categoryId,
    imageUrls: [imageUrls?.[0] ?? ''],

    price: mapPriceToListingPriceType(price),
    url: seoUrl,
    title: title,
    location: { id: locationId, name: '' },
  }
}
