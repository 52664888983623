import { type CoreListing, usePopularListingsQuery } from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { type FC, useMemo } from 'react'

import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { LoadingGallery } from '@/components/shared/loading-gallery/LoadingGallery'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { getPopularListingsVariables } from '@/features/listing/components/galleries/popular-listings/getListingsPopular'
import { PopularListingsWrapper } from '@/features/listing/components/galleries/popular-listings/styled'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'

/**
 * Props for the PopularListings component.
 *
 * @param locationId - The ID of the location to filter popular listings.
 * @todo KJCA-6251: After API work on adding category ID to params, update the Anvil endpoint to
 * include category ID, and update the props here to include category ID.
 */
export type PopularListingsProps = {
  locationId: number
}

export const PopularListings: FC<PopularListingsProps> = ({ locationId }) => {
  const { status } = useSession()
  const skipLoadingSkeletonForSSR = isUserAuthenticated(status)

  const initialFetchVariables = useMemo(() => getPopularListingsVariables(locationId), [locationId])

  const { loading, data } = usePopularListingsQuery({
    skip: !isValidLocation(locationId),
    variables: initialFetchVariables,
  })

  const handleImpressionsTracking = (listingId: string, position: number) => {
    trackEvent({
      action: GA_EVENT.SelectPromotion,
      label: `partnerid=${listingId};position=${position}`,
    })
  }

  const slides =
    data?.listingsPopular?.listings?.map((item, index) => {
      if (!item) return null

      const position = index + 1
      return (
        <CoreListingCard
          key={`popular-listings-${item.id}`}
          index={index}
          listing={item}
          linkTarget="_self"
          onClick={() => handleImpressionsTracking(item.id, position)}
        />
      )
    }) || []

  if (!loading && !data?.listingsPopular?.listings?.length) return null

  return (
    <PopularListingsWrapper data-testid="popular-listings-section">
      {loading && skipLoadingSkeletonForSSR ? (
        <Flex flexDirection="column" data-testid="popular-listings-loading">
          <LoadingGallery hasLoadingTitle itemsCount={6} useLegacyLgDesktopBreakpoint />
        </Flex>
      ) : (
        <ListingsCarousel
          enableImpressionsTracking
          trackSlidesListings={
            data?.listingsPopular?.listings?.filter((item): item is CoreListing => item !== null) ??
            []
          }
          slides={slides}
          name="popular-listings"
          shouldShowArrows={{ small: false, medium: false, large: true }}
          customShowcaseOptions={{ slidesToScroll: 'auto' }}
          slidesToShow={{
            small: 2,
            medium: 3,
            large: 4,
          }}
        />
      )}
    </PopularListingsWrapper>
  )
}
