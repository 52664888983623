import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import {
  type PopularListingsQuery,
  type PopularListingsQueryVariables,
  PopularListingsDocument,
} from '@kijiji/generated/graphql-types'

export const getPopularListingsVariables = (locationId: number): PopularListingsQueryVariables => ({
  locationId,
})

/**
 * Fetches popular listings data using Apollo Client.
 *
 * @function getListingsPopular
 * @param apolloClient - The Apollo Client instance to use for the query.
 * @param variables - The variables to pass to the popular listings query.
 * @returns A promise that resolves to the query result containing popular listings data.
 */
export const getListingsPopular = async ({
  apolloClient,
  variables,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>
  variables: PopularListingsQueryVariables
}) => {
  return await apolloClient.query<PopularListingsQuery, PopularListingsQueryVariables>({
    query: PopularListingsDocument,
    variables,
  })
}
