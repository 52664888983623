import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import {
  type GetListingHomepageGalleryQuery,
  type GetListingHomepageGalleryQueryVariables,
  DeviceType,
  GetListingHomepageGalleryDocument,
} from '@kijiji/generated/graphql-types'

import { HP_GALLERY_REQUEST_CHUNKS } from '@/components/homepage/shared/homepageConstants'
import { isMobileDevice } from '@/utils/userAgent'

export const getListingHomepageGalleryVariables = (
  userAgent: string,
  locationId: number,
  page: number = 0
): GetListingHomepageGalleryQueryVariables => ({
  deviceType: isMobileDevice(userAgent) ? DeviceType.Mweb : DeviceType.Desktop,
  locationId: locationId,
  pagination: {
    limit: HP_GALLERY_REQUEST_CHUNKS,
    offset: page,
  },
})

export const getListingHomepageGallery = async ({
  apolloClient,
  variables,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>
  variables: GetListingHomepageGalleryQueryVariables
}) => {
  return await apolloClient.query<
    GetListingHomepageGalleryQuery,
    GetListingHomepageGalleryQueryVariables
  >({
    query: GetListingHomepageGalleryDocument,
    variables,
    fetchPolicy: 'cache-first',
  })
}
