import { theme } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'

import {
  HeroContainer,
  HeroPicture,
} from '@/components/shared/cms-modules/browse-hero-static-banner/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { ROUTE_LOCALE, useLocale } from '@/hooks/useLocale'
import { MEDIA_QUERIES_SHORT } from '@/ui/constants/mediaQueries'
import kijijiDesktopBannerEn from '~/public/next-assets/images/homepage/kijiji-desktop-banner-en.png'
import kijijiDesktopBannerFr from '~/public/next-assets/images/homepage/kijiji-desktop-banner-fr.png'
import kijijiMobileBannerEn from '~/public/next-assets/images/homepage/kijiji-mobile-banner-en.png'
import kijijiMobileBannerFr from '~/public/next-assets/images/homepage/kijiji-mobile-banner-fr.png'
import kijijiTabletBannerEn from '~/public/next-assets/images/homepage/kijiji-tablet-banner-en.png'
import kijijiTabletBannerFr from '~/public/next-assets/images/homepage/kijiji-tablet-banner-fr.png'

type BrowseHeroStaticBannerProps = {
  href: string
}

export const BrowseHeroStaticBanner: FC<BrowseHeroStaticBannerProps> = ({ href }) => {
  const { t } = useTranslation('home')
  const { routeLocale } = useLocale()
  const isFrench = routeLocale === ROUTE_LOCALE.fr

  return (
    <HeroContainer data-testid="browse-hero-static-banner">
      <LinkCustom href={href}>
        <HeroPicture>
          <source
            srcSet={isFrench ? kijijiDesktopBannerFr.src : kijijiDesktopBannerEn.src}
            type="image/png"
            media={`(min-width: ${LEGACY_HP_LG_BREAKPOINT}px)`}
          />
          <source
            srcSet={isFrench ? kijijiTabletBannerFr.src : kijijiTabletBannerEn.src}
            type="image/png"
            media={MEDIA_QUERIES_SHORT(theme).medium}
          />
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt={t('static_banner.altKey')}
            src={isFrench ? kijijiMobileBannerFr.src : kijijiMobileBannerEn.src}
          />
        </HeroPicture>
      </LinkCustom>
    </HeroContainer>
  )
}
