import { type UserLocation } from '@kijiji/generated/graphql-types'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HomepageGallery } from '@/components/homepage/homepage-gallery/HomepageGallery'
import { KijijiCentralGallery } from '@/components/homepage/kijiji-central-gallery/KijijiCentralGallery'
import { PopularOnAutos } from '@/components/homepage/popular-categories/PopularOnAutos'
import { PopularOnBuyAndSell } from '@/components/homepage/popular-categories/PopularOnBuyAndSell'
import { PopularOnRealEstate } from '@/components/homepage/popular-categories/PopularOnRealEstate'
import { PopularNearYou } from '@/components/homepage/popular-near-you/PopularNearYou'
import { RecentlyViewedAds } from '@/components/homepage/recently-viewed-ads/RecentlyViewedAds'
import { RecommendedForYou } from '@/components/homepage/recommended-for-you/RecommendedForYou'
import { HomepageSectionWrapper } from '@/components/homepage/styled'
import { GptLeaderBase } from '@/components-page/homepage/advertisement/GptLeaderBase'
import { GptLeaderBottom } from '@/components-page/homepage/advertisement/GptLeaderBottom'
import { SignInBanner } from '@/features/auth/components/sign-in-banner/SignInBanner'
import { ListingsMostRecentGallery } from '@/features/listing/components/galleries/most-recent-listings/ListingsMostRecentGallery'
import { PopularListings } from '@/features/listing/components/galleries/popular-listings/PopularListings'
import { useBrandedTileCampaign } from '@/hooks/campaigns/useBrandedTileCampaign'
import { useLocale } from '@/hooks/useLocale'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'
import { Flex } from '@/ui/atoms/flex'
import { Spacing } from '@/ui/atoms/spacing'

type FeedFallbackComponentsProps = {
  getSeoUrl: (categoryId: number, index?: number | undefined) => string
  location: UserLocation
  userAgent: string
  isUserLoggedIn: boolean
}
export const FeedFallbackComponents: FC<FeedFallbackComponentsProps> = ({
  getSeoUrl,
  location,
  userAgent,
  isUserLoggedIn,
}) => {
  const { apiLocale } = useLocale()
  const { spacing } = useTheme()
  const popularNearYouToggleHorizontal = useToggle(RemoteParamKeys.POPULAR_NEAR_YOU_HORIZONTAL)
  const homepagePopularListingsEnabled = useToggle(RemoteParamKeys.HOMEPAGE_POPULAR_LISTINGS)

  /**
   * The branded tile campaign is used on the popular categories section
   * it will be added to the first tile in the assigned category section
   * And will remove its last static item.
   *  */
  const { campaign } = useBrandedTileCampaign(location.id)

  return (
    <Flex flexDirection="column" gap={spacing.husky}>
      <Spacing mTop={spacing.defaultSmall} />
      <RecommendedForYou />

      <HomepageGallery
        userAgent={userAgent}
        locationName={location.name[apiLocale]}
        locationId={location.id}
      />
      {!isUserLoggedIn && ( // eventually, we'll show this to all users
        <ListingsMostRecentGallery
          locationId={location.id}
          locationName={location.name[apiLocale]}
        />
      )}
      {popularNearYouToggleHorizontal?.enabled && (
        <PopularNearYou getSeoUrl={getSeoUrl} layout="horizontal" />
      )}

      <HomepageSectionWrapper>
        <PopularOnAutos
          campaign={campaign}
          getSeoUrl={getSeoUrl}
          isRegionLocation={location.isRegion}
          locationName={location.name[apiLocale]}
        />
        {homepagePopularListingsEnabled?.enabled && <PopularListings locationId={location.id} />}
      </HomepageSectionWrapper>
      <HomepageSectionWrapper>
        <GptLeaderBottom />
      </HomepageSectionWrapper>
      <RecentlyViewedAds />

      <PopularOnRealEstate
        campaign={campaign}
        getSeoUrl={getSeoUrl}
        isRegionLocation={location.isRegion}
        locationName={location.name[apiLocale]}
      />
      {homepagePopularListingsEnabled?.enabled && <PopularListings locationId={location.id} />}
      <HomepageSectionWrapper removeMobilePadding>
        <SignInBanner />
      </HomepageSectionWrapper>
      <HomepageSectionWrapper>
        <GptLeaderBase />
      </HomepageSectionWrapper>

      <PopularOnBuyAndSell
        campaign={campaign}
        getSeoUrl={getSeoUrl}
        isRegionLocation={location.isRegion}
        locationName={location.name[apiLocale]}
      />
      {homepagePopularListingsEnabled?.enabled && <PopularListings locationId={location.id} />}

      <KijijiCentralGallery />
    </Flex>
  )
}
