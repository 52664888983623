import { CATEGORIES } from '@kijiji/category'
import {
  useGetCategoriesNearYouQuery,
  useGetPopularKeywordsNearYouQuery,
} from '@kijiji/generated/graphql-types'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC, Fragment } from 'react'
import { useTheme } from 'styled-components'

import {
  ITEMS_TO_SHOW,
  PopularNearYouContainer,
  PopularNearYouLink,
  PopularNearYouList,
  PopularNearYouListItem,
  PopularNearYouWrapper,
} from '@/components/homepage/popular-near-you/styled'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT, GA_EVENT_LABEL } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Skeleton } from '@/ui/atoms/skeleton'
import { SkeletonContainer } from '@/ui/atoms/skeleton/styled'
import { Spacing } from '@/ui/atoms/spacing'
import { sendToLogger } from '@/utils/sendToLogger'
import { capitalizeString } from '@/utils/string'
import { attachOfferTypeToUrl, attachPriceTypeToUrl, removeFreeKeywordFromUrl } from '@/utils/url'

const RightArrowIcon = dynamic(() => import('@kijiji/icons/src/icons/Chevron'), { ssr: false })

type PopularNearYouLayout = 'horizontal' | 'vertical'

type PopularNearYouProps = {
  getSeoUrl: (categoryId: number, index?: number | undefined) => string
  layout?: PopularNearYouLayout
}

type PopularItemsListProps = {
  readonly items: readonly PopularItem[]
  readonly onItemClick: (item: PopularItem, index: number) => void
  readonly layout?: PopularNearYouLayout
}

type PopularItem = {
  label: string
  url: string
}

/**
 * Custom hook to fetch popular keywords near a given location.
 *
 * @param locationId - The ID of the location to fetch popular keywords for.
 * @returns An object containing the query data, loading state, and any errors.
 */
function usePopularNearYouData(locationId: number) {
  const { data, loading, error } = useGetPopularKeywordsNearYouQuery({
    fetchPolicy: 'cache-first',
    variables: {
      searchUrlPopularInput: {
        location: { id: locationId },
        categoryId: CATEGORIES.ROOT_CATEGORY_ID.toString(),
      },
    },
  })

  if (error) {
    sendToLogger('Error fetching popular near you data', {
      fingerprint: ['popular-near-you-error'],
      extra: { error, locationId },
    })
  }

  return { data, loading, error }
}

/**
 * Custom hook to fetch categories near a given location.
 *
 * @param locationId - The ID of the location to fetch categories for.
 * @returns The query result containing category data.
 */
function useCategoriesNearYou(locationId: number) {
  return useGetCategoriesNearYouQuery({
    fetchPolicy: 'cache-first',
    variables: {
      locationId,
    },
  })
}

/**
 * Generates fallback popular categories from the menu data.
 *
 * @param menu - The menu data containing fallback categories.
 * @returns An array of popular items with labels and URLs.
 */
function getFallbackPopularItems(
  menu: ReturnType<typeof useCategoriesNearYou>['data']
): PopularItem[] {
  return (menu?.menuPrefetch ?? [])
    .filter((item): item is NonNullable<typeof item> => item !== null)
    .map((item) => ({
      label: item.categoryName,
      url: item.seoUrl,
    }))
}

/**
 * Generates a custom URL for popular items based on their label.
 *
 * @param label - Label of the popular item.
 * @param defaultUrl - Dfault URL of the popular item.
 * @returns Custom URL for the popular item, with special handling for 'free' and 'gratuit' labels.
 */
function getPopularNearYouCustomUrl(label: string, defaultUrl: string): string {
  if (label.toLowerCase() === 'free' || label.toLowerCase() === 'gratuit') {
    return `${removeFreeKeywordFromUrl(defaultUrl, label)}?price-type=free`
  }

  return defaultUrl
}

/**
 * Generates static popular items with predefined labels and URLs.
 *
 * @param getSeoUrl - A function that generates the SEO URL for a given category ID.
 * @returns An array of static popular items with labels and URLs.
 */
function getStaticPopularItems(
  getSeoUrl: (categoryId: number) => string,
  t: (key: string) => string
): PopularItem[] {
  const allCategoriesUrl = getSeoUrl(ALL_CATEGORIES_ID_NUM)
  return [
    {
      label: t('popular_near_you.free.label'),
      url: attachPriceTypeToUrl(allCategoriesUrl, 'free'),
    },
    {
      label: t('popular_near_you.swap.label'),
      url: attachPriceTypeToUrl(allCategoriesUrl, 'swapTrade'),
    },
    {
      label: t('popular_near_you.wanted.label'),
      url: attachOfferTypeToUrl(allCategoriesUrl, 'wanted'),
    },
  ]
}

/**
 * Renders a list of popular items with custom URLs and handles item click events.
 *
 * @param items - Array of popular items to be displayed.
 * @param onItemClick - Function to handle click events on each item.
 * @returns List of popular items with custom URLs.
 */
function PopularItemsList({ items, onItemClick, layout }: PopularItemsListProps) {
  return (
    <PopularNearYouList $layout={layout}>
      {items.map((item, index) => {
        const customUrl = getPopularNearYouCustomUrl(item.label, item.url)

        return (
          <Fragment key={`hp-popular-near-you-${index + 1}`}>
            <PopularNearYouListItem $layout={layout}>
              <PopularNearYouLink
                href={customUrl}
                onClick={() => onItemClick(item, index)}
                $layout={layout}
              >
                {capitalizeString(item.label)}
                {layout === 'vertical' && <RightArrowIcon aria-hidden />}
              </PopularNearYouLink>
            </PopularNearYouListItem>
          </Fragment>
        )
      })}
    </PopularNearYouList>
  )
}

/**
 * Component to display popular searches near the user's location.
 *
 * The first 3 results are static links for specific filters (price free, price swap/trade,
 * offer type wanted). If there is no API error, displays popular keywords fetched from the
 * API. Otherwise, shows fallback categories.
 *
 * The number of popular keywords displayed is limited by the ITEMS_TO_SHOW value,
 * even though the API returns 10 results by default.
 *
 * @param getSeoUrl - A function that generates the SEO URL for a given category ID.
 *
 * @returns Component that displays a list of popular searches near the user's location.
 */
export const PopularNearYou: FC<PopularNearYouProps> = ({ getSeoUrl, layout }) => {
  const { t } = useTranslation('home')
  const { colors, spacing } = useTheme()
  const { location } = useGetLocation()

  const {
    data: popularNearYouData,
    loading: popularNearYouLoading,
    error: popularNearYouError,
  } = usePopularNearYouData(location.id || 0)
  const { data: categoriesData } = useCategoriesNearYou(location.id)

  const staticItems = getStaticPopularItems(getSeoUrl, t)
  const popularItems = popularNearYouData?.searchUrlsPopular?.length
    ? staticItems.concat(
        popularNearYouData.searchUrlsPopular.slice(0, ITEMS_TO_SHOW - staticItems.length)
      )
    : staticItems.concat(
        getFallbackPopularItems(categoriesData).slice(0, ITEMS_TO_SHOW - staticItems.length)
      )

  // Track the event with the keyword, button and position
  const handleItemClick = (item: PopularItem, index: number) => {
    const label = `keyword=${item.label};btn=${GA_EVENT_LABEL.POPULAR_NEAR_YOU};pos=${index + 1};layout=${layout}`

    trackEvent({
      action: GA_EVENT.CategorySelected,
      label,
    })
  }

  // Don't show the component if there's an error
  if (popularNearYouError) {
    return null
  }

  return (
    <PopularNearYouContainer $isFullWidth data-testid="popular-kijiji-home" $layout={layout}>
      <Spacing mBottom={spacing.large}>
        <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
          {t('popular_near_you.title')}
        </HeadlineText>
      </Spacing>
      <PopularNearYouWrapper $layout={layout}>
        {popularNearYouLoading ? (
          <PopularNearYouList $layout={layout}>
            {Array.from({ length: ITEMS_TO_SHOW }).map((_, index) => (
              <PopularNearYouListItem
                key={`skeleton-${index + 1}`}
                data-testid="skeleton"
                $layout={layout}
              >
                <SkeletonContainer style={{ padding: spacing.large }}>
                  <Skeleton height="2rem" width="80%" />
                </SkeletonContainer>
              </PopularNearYouListItem>
            ))}
          </PopularNearYouList>
        ) : (
          <PopularItemsList items={popularItems} onItemClick={handleItemClick} layout={layout} />
        )}
      </PopularNearYouWrapper>
    </PopularNearYouContainer>
  )
}
