import { CATEGORIES } from '@kijiji/category'

import { BrowseHeroStaticBanner } from '@/components/shared/cms-modules/browse-hero-static-banner/BrowseHeroStaticBanner'

type HeroCMSBannerProps = {
  getSRPUrl: (categoryId: number, index?: number) => string
}

export const HeroCMSBanner = ({ getSRPUrl }: HeroCMSBannerProps) => {
  return (
    <>
      <BrowseHeroStaticBanner href={getSRPUrl(CATEGORIES.BUY_AND_SELL_CATEGORY_ID)} />
    </>
  )
}
