import InfoTooltip from '@kijiji/icons/src/icons/InfoToolTip'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { HomepageGalleryTooltip } from '@/components/homepage/homepage-gallery/HomepageGalleryTooltip'
import {
  HomepageGalleryLinksContainer,
  TooltipButton,
} from '@/components/homepage/homepage-gallery/styled'
import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { useTooltipVisibility } from '@/hooks/useTooltipVisibility'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { Tooltip } from '@/ui/atoms/tool-tip'

export type HomepageGalleryHeaderProps = {
  /**
   * Track event for the "Your ad here" link
   */
  handleYourAdHereTracking?: () => void
  /**
   * User's current location Id
   */
  locationId: number
  /**
   * Current location name as fallback if SEO URL query fails
   */
  locationName?: string | null
  /**
   * Homepage Gallery See All link returned from Anvil
   */
  seeAllLink?: string
}

export const HomepageGalleryHeader: FC<HomepageGalleryHeaderProps> = ({
  locationName,
  locationId,
  handleYourAdHereTracking,
  seeAllLink,
}) => {
  const { spacing, colors } = useTheme()
  const { t } = useTranslation('home')
  const {
    handleDismissTooltip,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    handleViewTooltip,
    isTooltipVisible,
    tooltipRef,
  } = useTooltipVisibility()

  const fallbackSeeAllUrl = t('gallery.see_all.link', {
    seoLocation: `${locationName}`.toLowerCase().replace(/\s+/g, '-'),
    locationId,
  })

  return (
    <Spacing mBottom={spacing.defaultSmall} data-testid="hp-gallery-header">
      <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
            {t('gallery.title')}
          </HeadlineText>

          <Tooltip
            id="tooltip-homepage-gallery"
            isVisible={isTooltipVisible}
            onClose={handleDismissTooltip}
            onMouseEnter={handleTooltipMouseEnter}
            onMouseLeave={handleTooltipMouseLeave}
            orientation="top"
            tooltipId="hp-gallery-tooltip"
            tooltipText={
              <div ref={tooltipRef}>
                <HomepageGalleryTooltip />
              </div>
            }
            variant="light"
          >
            <TooltipButton
              role="tooltip"
              aria-labelledby="hp-gallery-tooltip-title"
              data-testid="hp-gallery-tooltip-button"
              onClick={handleViewTooltip}
              onMouseEnter={handleTooltipMouseEnter}
              onMouseLeave={handleTooltipMouseLeave}
            >
              <InfoTooltip aria-hidden />
            </TooltipButton>
          </Tooltip>
        </Flex>

        <HomepageGalleryLinksContainer gap={spacing.default}>
          <LinkCustom
            href={t('gallery.your_ad_here.link')}
            onClick={handleYourAdHereTracking}
            size="small"
            weight="regular"
          >
            {t('gallery.your_ad_here.label')}
          </LinkCustom>

          <LinkCustom
            href={seeAllLink ?? fallbackSeeAllUrl}
            weight="regular"
            size="small"
            variant="secondary"
          >
            {t('gallery.see_all.label')}
          </LinkCustom>
        </HomepageGalleryLinksContainer>
      </GalleryHeaderWrapper>
    </Spacing>
  )
}
