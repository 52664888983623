import {
  type CoreListing,
  useGetRecentlyViewedListingsQuery,
  useGetRecommendedForYouQuery,
} from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type ReactNode, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { SLIDES_PER_DEVICE } from '@/components/homepage/shared/homepageConstants'
import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

/**
 * Only display if:
 * - user is authenticated
 * - user has recently viewed ads
 * - location is Canada
 */
export const RecommendedForYou = () => {
  const { status, data: userData } = useSession()
  const userId = parseInt(`${userData?.user.sub}`)

  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')

  /**
   * We need the adIds from the user's recently viewed ads
   * to get the Recommendations for you ads
   */
  const { data: recentlyViewedListingsData } = useGetRecentlyViewedListingsQuery({
    skip: !isUserAuthenticated(status) || !userId,
    ssr: false,
    variables: { userId },
  })

  const listingIds = useMemo(
    () =>
      recentlyViewedListingsData?.user?.recentlyViewedListings?.reduce((acc: string[], curr) => {
        if (!curr?.id) return acc
        return [...acc, curr.id]
      }, []) ?? undefined,
    [recentlyViewedListingsData]
  )

  const { data: recommendedForYouData } = useGetRecommendedForYouQuery({
    skip: !isUserAuthenticated(status) || !userData?.user.sub || !listingIds,
    ssr: false,
    variables: { listingIds },
    onCompleted: ({ listingsRecommendedForYou }) => {
      if (listingsRecommendedForYou && listingsRecommendedForYou.length >= 5) {
        trackEvent({
          action: GA_EVENT.RecommendedForYouDisplayed,
        })
      }
    },
  })

  const listings: CoreListing[] =
    recommendedForYouData?.listingsRecommendedForYou?.filter((item) => !!item) ?? []

  const slides: ReactNode[] =
    listings?.map?.((item, index) => {
      if (!item) return null

      const handleListingClickTracking = () => {
        /** The position of the ad click is not tracked on this section */
        trackEvent({
          action: GA_EVENT.RecommendationAdClick,
          label: `adID=${item.id}`,
        })
      }

      return (
        <CoreListingCard
          key={`hp-recommended-for-you-${item.id}`}
          index={index}
          onClick={handleListingClickTracking}
          listing={item}
          linkTarget="_self"
        />
      )
    }) || []

  const hasEnoughAds = slides.length >= 5
  if (!hasEnoughAds) return null

  return (
    <Spacing mBottom={spacing.husky}>
      <GalleryWrapper data-testid="hp-recommended-for-you-section">
        <Spacing mBottom={spacing.defaultSmall} data-testid="hp-recommended-for-you-header">
          <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
            <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
              {t('recommended_for_you.title')}
            </HeadlineText>
          </GalleryHeaderWrapper>
        </Spacing>

        <Flex>
          <ListingsCarousel
            enableImpressionsTracking
            trackSlidesListings={listings}
            name="recommended-ads"
            slides={slides}
            shouldShowArrows={{ small: false, medium: false, large: true }}
            customShowcaseOptions={{ slidesToScroll: 'auto' }}
            slidesToShow={{
              small: SLIDES_PER_DEVICE.MOBILE,
              medium: SLIDES_PER_DEVICE.TABLET,
              large: SLIDES_PER_DEVICE.DESKTOP,
            }}
          />
        </Flex>
      </GalleryWrapper>
    </Spacing>
  )
}
