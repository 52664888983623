import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { Panel } from '@/ui/atoms/panel'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const ITEMS_TO_SHOW = 10

interface LayoutProps {
  $layout?: 'horizontal' | 'vertical'
  $isFullWidth?: boolean
}

export const PopularNearYouContainer = styled.div.attrs<LayoutProps>((props) => ({
  $isFullWidth: props.$isFullWidth,
  $layout: props.$layout,
}))<LayoutProps>(
  ({ theme, $layout }) => `
  padding: ${$layout === 'horizontal' ? '2rem' : '1.6rem'};
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    padding-left: ${$layout === 'horizontal' ? theme.spacing.husky : '0'};
  }

  ${MEDIA_QUERIES(theme).large} {
    margin-bottom: 0;
    padding: ${$layout === 'horizontal' ? '0 0 0 4rem' : '0'};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0;
  }
`
)

export const PopularNearYouList = styled.ul<LayoutProps>(({ theme, $layout }) => {
  const isVertical = $layout === 'vertical'
  return `
      display: flex;
      flex-direction: ${isVertical ? 'column' : 'row'};
      gap: ${theme.spacing.large};
      padding: 0;
      margin: 0;
      flex-grow: ${isVertical ? '1' : '0'};
      flex-wrap: ${isVertical ? 'nowrap' : 'wrap'};
      align-items: stretch;

      ${
        isVertical
          ? `
          li:not(:last-child) {
            padding-bottom: ${theme.spacing.default};
            border-bottom: .1rem solid ${theme.colors.grey.light4};
          }
        `
          : `
          display: grid;
          grid-template-columns: repeat(2,minmax(0,1fr));
          gap: ${theme.spacing.default};
          width: 100%;
          grid-auto-rows: 1fr;

          ${MEDIA_QUERIES(theme).medium}{
            grid-template-columns: repeat(4,minmax(0,1fr));
          }

          ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
            grid-template-columns: repeat(5,minmax(0,1fr));
          }
        `
      }
    `
})

export const PopularNearYouLink = styled(LinkCustom)<LayoutProps>(({ theme, $layout }) => {
  const isVertical = $layout === 'vertical'
  return `
      position: static;
      align-items: center;
      display: flex;
      flex-direction: ${isVertical ? 'row' : 'column'};
      height: 100%;
      justify-content: ${isVertical ? 'space-between' : 'center'};
      width: 100%;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      color: ${theme.colors.grey.primary};

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }

      ${
        !isVertical
          ? `
          &::after {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 1;
          }
        `
          : `
          svg {
            height: auto;
            width: 1.8rem;
          }
        `
      }
    `
})

export const ListingsCount = styled.span(
  ({ theme }) => `
  display: block;
  color: ${theme.colors.grey.light2};
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: ${theme.spacing.defaultSmall};
  pointer-events: none;
`
)

export const PopularNearYouWrapper = styled.div.attrs<LayoutProps>((props) => ({
  as: props.$layout === 'horizontal' ? 'div' : Panel,
}))<LayoutProps>(({ $layout }) => {
  const isVertical = $layout === 'vertical'

  return `
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: ${isVertical ? 'left' : 'center'};
      ${isVertical ? `min-height: ${ITEMS_TO_SHOW * 2}rem;` : ''}
    `
})

export const PopularNearYouListItem = styled.li<LayoutProps>(({ theme, $layout }) => {
  const isHorizontal = $layout === 'horizontal'
  return isHorizontal
    ? `
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          padding: 1rem;
          width: 100%;
          height: 100%;
          transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
          background: ${theme.colors.white};
          border-radius: ${theme.borderRadius.small};
          box-shadow: ${theme.boxShadow['shadow-1']};

          &:hover {
            transform: translateY(-2px) scale(1.01);
            box-shadow: ${theme.boxShadow['shadow-3']};
          }
        `
    : `
          width: 100%;
        `
})
