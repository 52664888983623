import { type CoreListing } from '@kijiji/generated/graphql-types'
import { type EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import throttle from 'lodash/throttle'
import dynamic from 'next/dynamic'
import {
  type FC,
  type HTMLAttributes,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  ListingCarouselList,
  ListingCarouselSlide,
  ListingsCarouselContainer,
  ListingsCarouselViewport,
  PrevNextBtnWrapper,
} from '@/components/shared/listings-carousel/styled'
import { useTrackCarouselImpressions } from '@/features/listing/hooks/useTrackCarouselImpressions'
import { type ResponsiveProp } from '@/ui/typings/helpers'

const NextButton = dynamic(
  () =>
    import('@/components/shared/carousel-buttons/CarouselButtons').then((mod) => mod.NextButton),
  { ssr: false }
)
const PrevButton = dynamic(
  () =>
    import('@/components/shared/carousel-buttons/CarouselButtons').then((mod) => mod.PrevButton),
  { ssr: false }
)

/** It should only require the CoreListings if enableImpressionsTracking is true */
type EnableImpressionsTracking =
  | {
      enableImpressionsTracking?: boolean
      trackSlidesListings?: CoreListing[]
    }
  | {
      enableImpressionsTracking?: false
      trackSlidesListings?: never
    }
  | {
      enableImpressionsTracking: true
      trackSlidesListings: CoreListing[]
    }

export type ListingsCarouselProps = EnableImpressionsTracking & {
  /**
   * Set callback function to be triggered when user hits a certain % of the scrollable content
   */
  callbackOnScroll?: {
    /**
     * Function to be triggered when scroll threshold is been hit
     */
    callbackFn: () => void | Promise<void>
    /**
     * Scrolling percentage to trigger the callback
     */
    percentage: number
  }
  /**
   * Custom props for the list (ul) node
   * */
  listCustomProps?: HTMLAttributes<HTMLUListElement> & { ref: MutableRefObject<null> }
  /**
   * Should be set to true if slides passed as props are already a list item
   * */
  preventNestedListItem?: boolean
  /**
   * Defines if arrow should show in different breakpoints or generally
   * i.e. {small: false, medium: true}
   */
  shouldShowArrows: ResponsiveProp<boolean>
  /**
   * Tiles/Slides to be displayed in the carousel
   */
  slides: ReactNode[]
  /**
   * Defines slide sizes per breakpoints depending on how many it should be shown
   * If no definition is passed, it will take default size of the slides
   * i.e. [desktop, tablet, mobile] -> { small: 2, medium: 4, large: 5 }
   * Order is important, it should be from small to large
   */
  slidesToShow?: ResponsiveProp<number>
  /**
   * Custom css styling for slides
   * It helps adding extra breakpoint or custom definitions for exceptions
   */
  slideCustomStyle?: string
  /**
   * name for generating unique keys
   */
  name: string
  /**
   * Custom options for the Embla carousel, can be used to override the default options set below
   */
  customShowcaseOptions?: EmblaOptionsType
}

export const ListingsCarousel: FC<ListingsCarouselProps> = ({
  enableImpressionsTracking,
  trackSlidesListings = [],
  callbackOnScroll,
  customShowcaseOptions,
  listCustomProps,
  name,
  preventNestedListItem,
  shouldShowArrows = { small: false, large: true },
  slideCustomStyle,
  slides,
  slidesToShow = { large: 5, medium: 4, small: 2 },
}) => {
  const [slidesInView, setSlidesInView] = useState<number[]>([])
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const { carouselRef, carouselInView } = useTrackCarouselImpressions({
    carouselName: name,
    isTrackingEnabled: !!enableImpressionsTracking,
    listings: trackSlidesListings,
    slidesInView,
  })

  const showcaseOptions: EmblaOptionsType = {
    active: true,
    containScroll: 'trimSnaps',
    dragFree: true,
    dragThreshold: 0,
    inViewThreshold: 0.4,
    skipSnaps: true,
    /**
     * This condition is mostly for mobile devices.
     * If there are less than 3 listings, when trying to scroll, embla will try to do 3 at a time
     * Which won't be possible, so the user won't be able to scroll to the last couple of items
     * This condition fixes those edge cases.
     * */
    slidesToScroll: slides.length > 3 ? 3 : 1,
    ...customShowcaseOptions,
  }

  const [emblaRef, emblaApi] = useEmblaCarousel(showcaseOptions)
  const [slideToTriggerLoadMore, setSlideToTriggerLoadMore] = useState<number>()

  /**
   * This function will trigger a callback on scroll if any is passed
   * This will allow us using "fetchMore" functionality on this carousel
   */
  const handleCallbackOnScroll = () => {
    if (!emblaApi || !callbackOnScroll) return

    const slidesInView = emblaApi.slidesInView()

    const { percentage, callbackFn } = callbackOnScroll
    const slidesCount = slides.length
    const triggerOnSlide = Math.floor((slidesCount * percentage) / 100)

    if (triggerOnSlide === slideToTriggerLoadMore) return

    if (slidesInView?.includes(triggerOnSlide)) {
      callbackFn()
      setSlideToTriggerLoadMore(triggerOnSlide)
    }
  }

  useEffect(() => {
    /**
     * If user selects arrows or scrolls carousel
     */
    const handleOnScroll = throttle(handleCallbackOnScroll, 800, { leading: true })
    emblaApi?.on('scroll', handleOnScroll)
    emblaApi?.on('select', handleOnScroll)

    return () => {
      emblaApi?.off('scroll', handleOnScroll)
      emblaApi?.off('select', handleOnScroll)
    }
  })

  useEffect(() => {
    if (!emblaApi) return

    // hard reset carousel for options changes
    emblaApi.reInit()
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return

    const onSelect = () => {
      if (!emblaApi) return

      setPrevBtnEnabled(emblaApi.canScrollPrev())
      setNextBtnEnabled(emblaApi.canScrollNext())
    }
    onSelect()

    // add embla event listeners
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)

    return () => {
      // remove embla event listeners
      emblaApi.off('select', onSelect)
      emblaApi.off('reInit', onSelect)
    }
  }, [emblaApi])

  const updateSlidesInView = useCallback(() => {
    if (!emblaApi) return

    /** Get slides in view */
    const visibleSlides = emblaApi.slidesInView()
    setSlidesInView(visibleSlides)
  }, [emblaApi])

  /** Track only slides "inView" */
  useEffect(() => {
    /** It should only trigger useEffect if the parent needs tracking on InView slides */
    if (!enableImpressionsTracking) return

    if (!emblaApi || !carouselInView) return

    /**
     * Update slides at the end of scrolling
     */
    emblaApi.on('scroll', throttle(updateSlidesInView, 1000, { leading: true }))
    /** First time the carousel is in view */
    updateSlidesInView()

    return () => {
      emblaApi.off('scroll', updateSlidesInView)
    }
  }, [emblaApi, enableImpressionsTracking, carouselInView, updateSlidesInView])

  /**
   * If we define scrollNext/Prev(true) emblaApi.on('scroll') won't be triggered on prev/next buttons click
   * In that case the scroll will only be triggered when a user dags the carousel
   *
   * Setting scrolling behaviour as "instant" (true) will the break impressions tracking on button click.
   */
  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev()
    }
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext()
    }
  }, [emblaApi])

  return (
    <ListingsCarouselContainer ref={carouselRef}>
      <ListingsCarouselViewport ref={emblaRef}>
        <ListingCarouselList {...listCustomProps}>
          {slides.map((child, index) => (
            <ListingCarouselSlide
              as={preventNestedListItem ? 'div' : 'li'}
              key={`${name}-slide-${index}`}
              slideCustomStyle={slideCustomStyle}
              slidesToShow={slidesToShow}
            >
              {child}
            </ListingCarouselSlide>
          ))}
        </ListingCarouselList>

        {shouldShowArrows && (
          <PrevNextBtnWrapper shouldShowArrows={shouldShowArrows}>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </PrevNextBtnWrapper>
        )}
      </ListingsCarouselViewport>
    </ListingsCarouselContainer>
  )
}
