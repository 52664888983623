import { type CoreListing } from '@kijiji/generated/graphql-types'
import { useEffect, useRef, useState } from 'react'

import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { getSearchImpressions } from '@/lib/ga/utils/getSearchImpressions'

type ListingsToTrack = {
  listings: CoreListing[]
  slides: number[]
}

type UseTrackCarouselImpressionsProps = {
  /** Name of the carousel used as the label of the GA event */
  carouselName: string
  /** Should only track impressions if tracking is enabled to the carousel */
  isTrackingEnabled: boolean
  /** List of all the listings in the carousel */
  listings: CoreListing[]
  /** List of the slide numbers in view */
  slidesInView: number[]
}

/**
 * This hook uses an Intersection Observer to identify when the carousel is in view to track impressions for the listings current present
 * And creates the function that will handle tracking the listings in view on scroll
 */
export const useTrackCarouselImpressions = ({
  carouselName,
  isTrackingEnabled,
  listings,
  slidesInView,
}: UseTrackCarouselImpressionsProps) => {
  const carouselRef = useRef<HTMLDivElement>(null)

  /**
   * Saves the slides that have already been tracked
   * We should only track each slide impression once per page load
   * */
  const [trackedSlides, setTrackedSlides] = useState<number[]>([])
  const [carouselInView, setCarouselInView] = useState<boolean>(false)

  useEffect(() => {
    if (!isTrackingEnabled) return

    const handleSlidesTracking = (indexesInView: number[]) => {
      /** It should only track indexes that were not tracked yet */
      const listingsToTrack = indexesInView.reduce(
        (acc: ListingsToTrack, curr: number) => {
          const listing: CoreListing | undefined = listings[curr]
          if (trackedSlides.includes(curr) || !listing) return acc

          return {
            listings: [...acc.listings, listing],
            slides: [...acc.slides, curr],
          }
        },
        { listings: [], slides: [] }
      )

      if (listingsToTrack.slides.length) {
        setTrackedSlides((prev) => [...prev, ...listingsToTrack.slides])
        const impressions = getSearchImpressions({ listings: listingsToTrack.listings })

        trackEvent({
          action: GA_EVENT.ViewPromotion,
          label: carouselName,
          customParameters: { ecommerce: impressions },
        })
      }
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        /** Triggers while the carousel is in view */
        if (carouselInView !== entry.isIntersecting) setCarouselInView(entry.isIntersecting)

        if (entry.isIntersecting) {
          handleSlidesTracking(slidesInView)
        }
      },
      {
        /** Viewport as the root */
        root: null,
        rootMargin: '0px',
        /** At least 50% of the section needs to be in view  */
        threshold: 0.5,
      }
    )

    const section = carouselRef.current
    if (section) observer.observe(section)

    /** Cleanup observer on component unmount */
    return () => {
      if (section) observer.unobserve(section)
    }
  }, [carouselInView, isTrackingEnabled, listings, slidesInView, trackedSlides, carouselName])

  return { carouselRef, carouselInView: carouselInView }
}
