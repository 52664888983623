import styled from 'styled-components'

import { MEDIA_QUERY_HP_LARGE_DESKTOP } from '@/components/homepage/styled'
import { MAX_APP_WIDTH } from '@/constants/others'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const HeroContainer = styled.div(
  ({ theme }) => `
  max-width: 100%;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    max-width: ${MAX_APP_WIDTH};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    margin: 0 auto;
    margin-left: auto;
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0 8rem;
  }
`
)

export const HeroPicture = styled.picture`
  img {
    width: 100%;
  }
`
