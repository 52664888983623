import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { MAX_CORE_CARD_WIDTH } from '@/components/shared/core-listing-card/styled'
import { type ListingsCarouselProps } from '@/components/shared/listings-carousel/ListingsCarousel'
import { Flex } from '@/ui/atoms/flex'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'

export const ListingsCarouselContainer = styled.div`
  position: relative;
  width: 100%;
`

/**
 * It will hide the slides, but not the arrows as they are absolute to the outer container
 */
export const ListingsCarouselViewport = styled.div`
  overflow: hidden;
`

export const ListingCarouselList = styled.ul(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.default};
  height: auto;
  padding: 0 ${theme.spacing.large} 0 ${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium}{
    padding: 0;
  }
`
)

type SlideProps = {
  slidesToShow?: ListingsCarouselProps['slidesToShow']
  slideCustomStyle?: string
}

/**
 * Calculates the slide width for the carousel.
 * Takes into account the flex gap and the number of slides to show.
 * Slides are restricted to a min-width of 14.4rem (MIN_CORE_CARD_WIDTH)
 *
 * @param value - The number of slides to show.
 * @param theme - The theme properties containing spacing information.
 * @returns A string representing the CSS flex property value.
 */
const getResponsiveSlidesToScroll = (value: SlideProps['slidesToShow'], theme: ThemeProps) => `
  flex: 0 0 calc((100% - (${value} - 1) *  ${theme.spacing.default}) / ${value});
`

export const ListingCarouselSlide = styled.li<SlideProps>(
  ({ theme, slidesToShow, slideCustomStyle }) => `
    display: flex;
    flex: 0 0 auto;
    max-width: calc(${MAX_CORE_CARD_WIDTH});
    width: 100%;
    background-color: ${theme.colors.white};

    & > div {
      width: 100%;
    }

    ${applyResponsiveStyle<SlideProps['slidesToShow']>(slidesToShow, theme, getResponsiveSlidesToScroll)}
    ${slideCustomStyle ?? ''}
  `
)

type PrevNextBtnWrapperProps = {
  shouldShowArrows: ListingsCarouselProps['shouldShowArrows']
}

const getResponsiveDisplayArrows = (value: PrevNextBtnWrapperProps['shouldShowArrows']) => `
  display: ${value ? 'block' : 'none'};
`
export const PrevNextBtnWrapper = styled.div<PrevNextBtnWrapperProps>(
  ({ theme, shouldShowArrows }) => `
    ${applyResponsiveStyle(shouldShowArrows, theme, getResponsiveDisplayArrows)}
  `
)

/** LOADING CAROUSEL */
export const LocatingListingsCarouselContainer = styled(Flex)(
  ({ theme }) => `
  width: 100%;
  overflow: hidden;
  padding-left: ${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium} {
    padding-left: 0;
  }
`
)
