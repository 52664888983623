import { theme } from '@kijiji/theme'

import {
  adSizesMapHomepage,
  BASE_AD_PATH_FOR_HP,
  HOMEPAGE_GPT_ID,
  HOMEPAGE_PREBID_SLOTS,
} from '@/components-page/homepage/advertisement/homepageAdSlots'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { PREBID_GLOBAL_PROPERTIES } from '@/features/advertisement/constants/adSlots'
import { AMAZON_TAM_CONFIG } from '@/features/advertisement/constants/tam'
import { type AdConfig, type GptTargeting } from '@/features/advertisement/types/adConfig'
import { flattenGptArray } from '@/features/advertisement/utils/globalOptimizationTool'
import { type RouteLocale } from '@/hooks/useLocale'

export const createHomepageGptConfig = (
  gptTargetingValues: GptTargeting[],
  routeLocale: RouteLocale,
  shouldShowHomepageFeed: boolean,
  numOfHomeFeedAds: number
) => {
  const pathLocale = routeLocale === 'fr' ? '-fr' : '' // no suffix for English
  const adUnitPathWithLocale = `${BASE_AD_PATH_FOR_HP}${pathLocale}`

  const homeFeedAdSlots = Array.from({ length: numOfHomeFeedAds }, (_, i) => ({
    id: `${HOMEPAGE_GPT_ID.LEADER_BASE}-${i + 1}`, // eg gpt-leader-base-1, gpt-leader-base-2, etc
    sizeMappingName: HOMEPAGE_GPT_ID.LEADER_BASE_HOMEFEED,
    sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.LEADER_BASE_HOMEFEED],
    targeting: { pos: 'base', tile: `${i + 4}` }, // starts at 4
    prebid: [],
  }))

  const homepageGptConfig: AdConfig = {
    // global settings for GPT
    path: adUnitPathWithLocale,
    /**
     * TODO: We may need to fine tune lazy loading at either global or slot level.
     * On FES, it's at 40px from the bottom of the viewport
     * but we can only set a percentage for React Advertising.
     */
    enableLazyLoad: {
      marginPercent: 5, // 5% from the bottom of the viewport
      mobileScaling: 2,
    },
    prebid: PREBID_GLOBAL_PROPERTIES(),
    aps: AMAZON_TAM_CONFIG,
    useAPS: true,
    sizeMappings: {
      [HOMEPAGE_GPT_ID.LEADER_BASE]: [
        {
          viewPortSize: [0, 0],
          sizes: [],
        },

        {
          viewPortSize: [theme.breakpoints.medium, 0],
          sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.LEADER_BASE],
        },
      ],
      [HOMEPAGE_GPT_ID.LEADER_BASE_HOMEFEED]: [
        {
          viewPortSize: [0, 0],
          sizes: [adSizesMapHomepage[HOMEPAGE_GPT_ID.LEADER_BASE_HOMEFEED][0]],
        },

        {
          viewPortSize: [theme.breakpoints.medium, 0],
          sizes: [adSizesMapHomepage[HOMEPAGE_GPT_ID.LEADER_BASE_HOMEFEED][1]],
        },
      ],
      [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: [
        {
          viewPortSize: [0, 0],
          sizes: [],
        },

        {
          viewPortSize: [LEGACY_HP_LG_BREAKPOINT, 0],
          sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.RIGHTRAIL_TOP],
        },
      ],
      [HOMEPAGE_GPT_ID.GPT_STICKY]: [
        {
          viewPortSize: [0, 0],
          sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.GPT_STICKY],
        },

        {
          viewPortSize: [theme.breakpoints.medium, 0],
          sizes: [],
        },
      ],
    },

    // page-level targeting values for GPT
    targeting: flattenGptArray(gptTargetingValues),
    // slot-level settings override the global settings above
    slots: [
      {
        id: HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING,
        sizeMappingName: HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING,
        sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING],
        targeting: { pos: 'top', tile: '1' },
        prebid: [], // need to include an empty array to prevent issue with React Advertising
      },
      {
        id: HOMEPAGE_GPT_ID.RIGHTRAIL_TOP,
        sizeMappingName: HOMEPAGE_GPT_ID.RIGHTRAIL_TOP,
        sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.RIGHTRAIL_TOP],
        targeting: { pos: 'top', tile: '2' },
        prebid: [HOMEPAGE_PREBID_SLOTS.RIGHTRAIL_TOP],
      },
      {
        id: HOMEPAGE_GPT_ID.GPT_STICKY,
        sizeMappingName: HOMEPAGE_GPT_ID.GPT_STICKY,
        sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.GPT_STICKY],
        targeting: { pos: 'top' },
        prebid: [],
      },
      ...(shouldShowHomepageFeed
        ? []
        : [
            {
              id: HOMEPAGE_GPT_ID.LEADER_BOTTOM,
              sizeMappingName: HOMEPAGE_GPT_ID.LEADER_BOTTOM,
              sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.LEADER_BOTTOM],
              targeting: { pos: 'bottom', tile: '4' },
              prebid: [HOMEPAGE_PREBID_SLOTS.LEADER_BOTTOM],
            },
            {
              id: HOMEPAGE_GPT_ID.LEADER_BASE,
              sizeMappingName: HOMEPAGE_GPT_ID.LEADER_BASE,
              sizes: adSizesMapHomepage[HOMEPAGE_GPT_ID.LEADER_BASE],
              targeting: { pos: 'base', tile: '5' },
              prebid: [],
            },
          ]),
      ...homeFeedAdSlots,
    ],
  }

  return homepageGptConfig
}
