import {
  RecommendationProcessor,
  useGetFeedRecommendationsQuery,
} from '@kijiji/generated/graphql-types'
import truncate from 'lodash/truncate'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { SLIDES_PER_DEVICE } from '@/components/homepage/shared/homepageConstants'
import { CoreListingCard } from '@/components/shared/core-listing-card/CoreListingCard'
import { mapToCoreListingCardData } from '@/components/shared/core-listing-card/utils/listingDataMassageUtils'
import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import { ListingsCarousel } from '@/components/shared/listings-carousel/ListingsCarousel'
import { getGaAdType } from '@/domain/ga/getGaAdType'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Flex } from '@/ui/atoms/flex'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

export type SimilarToFavouritesGalleryProps = {
  locationId: number
}

export const SimilarToFavouritesGallery: FC<SimilarToFavouritesGalleryProps> = ({ locationId }) => {
  const { status, data: userData } = useSession()
  const { colors, spacing } = useTheme()
  const { t } = useTranslation('home')

  const userId = parseInt(`${userData?.user.sub}`)

  /**
   * There is no pagination or fetchMore defined for this carousel
   */
  const { data: feedData } = useGetFeedRecommendationsQuery({
    skip: !isUserAuthenticated(status) || !userId,
    ssr: false,
    variables: {
      input: { processor: RecommendationProcessor.SimilarToFavourites, userId, locationId },
    },
    onCompleted: ({ feedRecommendations }) => {
      if (!feedRecommendations?.ads?.length || feedRecommendations.ads.length < 5) return

      /**
       * Impression tracking on section load
       * It has been flagged to the FAST team the weird pattern on the label:
       * - "block" is referring to VIP
       * - "position" is sending a hard coded text instead of a dynamic position value
       *  */
      trackEvent({
        action: GA_EVENT.ResultsImpressions,
        label: `block=SimilarToFavouriteVIP;position=$position;CTA=carousel;item=${feedRecommendations?.seedId};`,
      })
    },
  })

  const handleSeeTracking = () => {
    trackEvent({
      action: GA_EVENT.BlockTitleClick,
      label: `block=SimilarToFavouriteVIP;item=${feedData?.feedRecommendations?.seedId};CTA=carousel`,
    })
  }

  const slides: ReactNode[] =
    feedData?.feedRecommendations?.ads?.map((item, index) => {
      if (!item) return null

      const handleListingClickTracking = () => {
        trackEvent({
          action: GA_EVENT.RecommendationAdClick,
          label: `block=SimilarToFavouriteVIP;item=${feedData.feedRecommendations?.seedId};AdID=${
            item.id
          };adIndex=${index + 1};CTA=carousel;type=${getGaAdType(item.type)}`,
        })
      }

      return (
        <CoreListingCard
          allowFavourite
          index={index}
          key={`hp-similar-feed-${item.id}`}
          onClick={handleListingClickTracking}
          listing={mapToCoreListingCardData(item)}
          linkTarget="_self"
        />
      )
    }) || []

  const hasEnoughAds = slides.length >= 5
  if (!hasEnoughAds) return null

  const truncatedLink = truncate(feedData?.feedRecommendations?.description, { length: 35 })

  return (
    <Spacing mBottom={spacing.husky} mTop={spacing.husky}>
      <GalleryWrapper data-testid="hp-similar-to-favourites-section">
        <Spacing mBottom={spacing.defaultSmall} data-testid="hp-gallery-header">
          <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" flexWrap="wrap">
              <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
                {t('similar_to_favourites.title')}{' '}
                {feedData?.feedRecommendations ? (
                  <LinkCustom
                    href={feedData.feedRecommendations?.seoUrl}
                    onClick={handleSeeTracking}
                    target="_blank"
                    variant="secondary"
                  >
                    &quot;{truncatedLink}&quot;
                  </LinkCustom>
                ) : null}
              </HeadlineText>
            </Flex>
          </GalleryHeaderWrapper>
        </Spacing>

        <Flex>
          <ListingsCarousel
            name="hp-similar-to-favourites-carousel"
            slides={slides}
            shouldShowArrows={{ small: false, medium: false, large: true }}
            customShowcaseOptions={{ slidesToScroll: 'auto' }}
            slidesToShow={{
              small: SLIDES_PER_DEVICE.MOBILE,
              medium: SLIDES_PER_DEVICE.TABLET,
              large: SLIDES_PER_DEVICE.DESKTOP,
            }}
          />
        </Flex>
      </GalleryWrapper>
    </Spacing>
  )
}
