import { type TFunction } from 'i18next'

/**
 * Generates a "See All" link for the most recent listings.
 *
 * @param seoUrl - seoUrl returned by Anvil
 * @param locationId
 * @param locationName
 * @param t - The next-i18next translation function.
 * @returns The generated "See All" link as a string.
 *
 * If `seoUrl` is not provided, it returns a translated link using the location name and ID.
 * If `seoUrl` is provided, it removes the `sort=descOrder` query parameter for indexing reasons
 * and returns the modified URL. If an error occurs during URL processing, it returns the original `seoUrl`.
 */
export const getSeeAllLink = (
  seoUrl: string | null | undefined,
  locationId: number,
  locationName: string | null | undefined,
  t: TFunction
) => {
  if (!seoUrl) {
    return t('listings_most_recent.see_all.link', {
      seoLocation: `${locationName}`.toLowerCase().replace(/\s+/g, '-'),
      locationId,
    })
  }
  /**
   * For indexing reasons, we need to remove sort=descOrder
   * see https://jira.ets.mpi-internal.com/browse/KJCA-4445
   */
  try {
    const url = new URL(seoUrl)
    if (url.searchParams.get('sort') === 'dateDesc') {
      url.searchParams.delete('sort')
    }
    return url.toString()
  } catch {
    return seoUrl
  }
}
